<template>
  <div class="dress">
    <v-container v-if="loan">
      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Cliente</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          NOMBRE: {{ loan.client.first_name + " " + loan.client.last_name }}
        </v-col>
        <v-col cols="4">
          INDENTIFICACIÓN: {{ loan.client.colombian_id }}
        </v-col>
        <v-col cols="4"> DIRECCIÓN: {{ loan.client.address }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> TELÉFONO: {{ loan.client.phone }} </v-col>
        <v-col cols="4"> CORREO: {{ loan.client.email }} </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Alquiler
              <v-chip class="ma-2" color="secondary" text-color="white" small>
                {{ loan.reference }}
              </v-chip></v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> DIRECCIÓN: {{ loan.address }} </v-col>
        <v-col cols="4">
          NOMBRE DE REFERENCÍA: {{ loan.reference_name }}
        </v-col>
        <v-col cols="4">
          TELÉFONO DE REFERENCÍA: {{ loan.reference_phone }}
        </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4"> FECHA DE CONTRATO: {{ loan.loan_date }} </v-col>
        <v-col cols="4"> FECHA DE ENTREGA: {{ loan.event_date }} </v-col>
        <v-col cols="4"> FECHA DE DEVOLUCIÓN: {{ loan.return_date }} </v-col>
      </v-row>

      <v-row class="text-left pl-3">
        <v-col cols="4">
          VALOR A PAGAR: {{ loan.rental_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          VALOR DEPOSÍTO: {{ loan.deposit_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          VALOR DE DOMICILIO: {{ loan.address_value | formatNumber }}
        </v-col>
        <v-col cols="4">
          ASESOR: {{ loan.employee ? loan.employee.full_name : "N/A" }}
        </v-col>
        <v-col cols="12"> NOTA: {{ loan.note }} </v-col>
        <v-col cols="2">
          <v-btn
            elevation="2"
            :href="loan.invoice_url"
            rounded
            dark
            color="blue"
            target="_blank"
          >
            Factura
            <v-icon right dark> mdi-cloud-print </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            elevation="2"
            :href="loan.contract_url"
            rounded
            dark
            color="blue"
            target="_blank"
            >Contrato
            <v-icon right dark> mdi-file-document-multiple-outline </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Vestidos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row v-for="(dress, index) in loan.dresses" :key="index">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-avatar color="primary" size="25" class="white--text mr-3">{{
                index + 1
              }}</v-avatar>
              {{ dress.code }} - {{ dress.color }} - Alquiler:
              {{ dress.pivot.rental_value | formatNumber }} - Lavado:
              {{ dress.pivot.wash_value | formatNumber }}

              <v-spacer></v-spacer>

              <v-btn
                elevation="2"
                rounded
                dark
                color="blue"
                small
                @click="addPhotoStudio(dress)"
                v-if="!dress.pivot.photo_studio_date"
              >
                Agregar F. Estudio
              </v-btn>
            </v-card-title>
            <v-card-text>
              <h2 class="text-left mb-4">
                <v-icon>mdi-content-cut</v-icon>
                Arreglos
              </h2>
              <div
                v-for="(arrangement, indexA) in dress.arrangements"
                :key="indexA"
              >
                <p class="text-left">
                  <span>#{{ indexA + 1 }}</span>
                  <v-chip
                    class="ma-2"
                    :color="getStatusColor(arrangement.status)"
                    text-color="white"
                    small
                  >
                    {{ getStatusLan(arrangement.status) }}
                  </v-chip>
                  {{ arrangement.value | formatNumber }} -
                  {{ arrangement.description }}
                </p>
              </div>

              <h2 class="text-left mb-4" v-if="dress.pivot.photo_studio_date">
                <v-icon>mdi-camera</v-icon>
                Foto estudio
              </h2>
              <div class="text-left" v-if="dress.pivot.photo_studio_date">
                Fecha: {{ dress.pivot.photo_studio_date }} <br>
                Valor: {{ dress.pivot.photo_studio_value | formatNumber }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Pagos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersPayment"
            :items="loan.payments"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.value`]="item">
              {{ item.item.value | formatNumber }}
            </template>
            <template v-slot:[`item.payment_method`]="item">
              {{ getPaymentMethodsLan(item.item.payment_method) }}
            </template>
            <template v-slot:[`item.payment_type`]="item">
              {{ getPaymentTypeLan(item.item.payment_type) }}
            </template>
            <template v-slot:[`item.user`]="item">
              {{ item.item.user.name }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <v-tooltip bottom v-if="item.item.payment_type !== 'INITIAL'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text :href="item.item.invoice_url" target="_bank">
                    <v-icon
                      color="blue"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="px-1"
                    >
                      mdi-cloud-print
                    </v-icon>
                  </v-btn>
                </template>
                <span>FACTURA</span>
              </v-tooltip>
              <span v-if="item.item.payment_type == 'INITIAL'">N/A</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Aplazamientos</v-toolbar-title
            >
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersPostponements"
            :items="loan.postponements"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.created_at`]="item">
              {{ item.item.created_at.substr(0, 10) }}
            </template>
            <template v-slot:[`item.user`]="item">
              {{ item.item.user.name }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-toolbar class="secondary lighten-2 white--text" elevation="2">
            <v-toolbar-title class="font-weight-bold text-uppercase"
              >Notas
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" small fab dark color="indigo" @click="addNote">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersNotes"
            :items="loan.notes"
            class="elevation-1 mb-7"
            no-data-text="No hay registros agregados..."
            hide-default-footer
          >
            <template v-slot:[`item.created_at`]="item">
              {{ item.item.created_at.substr(0, 10) }}
            </template>
            <template v-slot:[`item.user`]="item">
              {{ item.item.user.name }}
            </template>
            <template v-slot:[`item.actions`]="item">
              <span v-if="role !== 'SUPERADMIN'"> N/A </span>
              <v-icon
                small
                @click="deleteNote(item.item.id)"
                v-if="role == 'SUPERADMIN'"
                color="red"
              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog add note -->
    <v-dialog v-model="dialogAddNota" persistent width="600">
      <v-card>
        <v-card-title>Agregar Nota</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-5">
          <v-form ref="formAddNote" v-model="formAddNota">
            <v-textarea
              v-model="modelAddNote.note"
              :rules="[rules.required]"
              label="Nota"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="blue darken-1" @click="closeDialogAddNote">
            Cancelar
          </v-btn>
          <v-btn text color="blue darken-1" @click="saveNote"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog delete note -->
    <v-dialog v-model="dialogDeleteNote" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Seguro que desea eliminar esta nota?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteNote"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteNoteConfirm"
            >Aceptar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Photo Studio -->
    <v-dialog v-model="dialogAddPhotoStudio" width="500" persistent>
      <v-card>
        <v-card-title>Agregar Foto Estudio</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="formAddPhotoStudio" v-model="formAddPhotoStudio">
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="modelPayment.photo_studio_date"
                        label="Fecha de Foto Estudio"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="modelPayment.photo_studio_date"
                      @input="menu = false"
                      locale="es-ES"                      
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="modelPayment.photo_studio_value"
                    label="Valor"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-select
                    v-model="modelPayment.payment_method"
                    label="Metodo de pago"
                    :rules="[rules.required]"
                    :items="this.$store.state.payments.paymentMethods"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" v-if="multilanguage">
                  <v-select
                    :items="idioms"
                    v-model="modelPayment.lan"
                    :rules="[rules.required]"
                    label="Idioma"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelPhotoStudio">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="savePhotoStudio">
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog loader -->
    <v-dialog
      v-model="this.$store.state.loan.create.isLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          Por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_LOAN } from "@/store/loan";
import { SET_ALERT } from "@/store/alert";
import { SET_USER } from "@/store/user";
import { mapGetters } from "vuex";
export default {
  name: "Show",
  props: ["id"],
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
      }).format(value);
    },
  },
  data: () => ({
    loan: null,
    menu: null,
    dialogAddPhotoStudio: false,
    formAddPhotoStudio: true,
    headersPayment: [
      { text: "Valor", value: "value", sortable: false },
      { text: "Método de pago", value: "payment_method", sortable: false },
      { text: "Tipo de pago", value: "payment_type", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
      { text: "Actiones", value: "actions", sortable: false },
    ],
    headersPostponements: [
      { text: "Radicado", value: "created_at", sortable: false },
      { text: "Antigua Fecha", value: "last_date", sortable: false },
      { text: "Nueva Fecha", value: "new_date", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
    ],
    headersNotes: [
      { text: "Fecha", value: "created_at", sortable: false },
      { text: "Nota", value: "note", sortable: false },
      { text: "Usuario", value: "user", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    dialogAddNota: false,
    dialogDeleteNote: false,
    formAddNota: true,
    modelAddNote: {
      note: "",
    },
    modelPayment: {      
      photo_studio_date: "",
      photo_studio_value: "",
      payment_method: "",
      lan: "es",
    },
    idioms: [
      { text: "Español", value: "es" },
      { text: "Ingles", value: "en" },
    ],
    rules: {
      required: (value) => !!value || "Campo obligatorio.",
    },
    currentNote: null,
    multilanguage: 0,
  }),
  created() {
    this.$store.dispatch("getLoan", this.id);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_LOAN) {
        this.loan = state.loan.loan;
      }
      if (mutation.type === SET_USER) {
        this.multilanguage = state.user.user.office.multilanguage;
      }
    });
  },
  computed: {
    ...mapGetters([
      "getPaymentMethodsLan",
      "getPaymentTypeLan",
      "getStatusColor",
      "getStatusLan",
    ]),
    role() {
      return this.$store.state.user.user.role;
    },
  },
  methods: {
    addNote() {
      this.dialogAddNota = true;
    },
    saveNote() {
      if (!this.$refs.formAddNote.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }
      this.modelAddNote.loan_id = this.loan.id;
      this.$store.dispatch("createNote", this.modelAddNote);
      this.closeDialogAddNote();
    },
    closeDialogAddNote() {
      this.dialogAddNota = false;
      this.$nextTick(() => {
        this.$refs.formAddNote.resetValidation();
        this.modelAddNote = {
          note: "",
        };
      });
    },
    deleteNote(id) {
      this.currentNote = id;
      this.dialogDeleteNote = true;
    },
    closeDeleteNote() {
      this.dialogDeleteNote = false;
      this.currentNote = null;
    },
    deleteNoteConfirm() {
      this.$store.dispatch("deleteNote", {
        note: this.currentNote,
        loan_id: this.loan.id,
      });
      this.closeDeleteNote();
    },
    addPhotoStudio(loanDress){
      this.currentLoanDress = loanDress;
      this.dialogAddPhotoStudio = true;
    },
    cancelPhotoStudio() {
      this.modelPayment = {        
        photo_studio_date: "",
        photo_studio_value: "",
        payment_method: "",
        lan: "es",
      };
      this.$refs.formAddPhotoStudio.resetValidation();
      this.dialogAddPhotoStudio = false;
    },
    savePhotoStudio() {
      if (!this.$refs.formAddPhotoStudio.validate()) {
        this.$store.commit(SET_ALERT, {
          message: "Todos los campos son obligatorios",
          type: "error",
        });
        return;
      }      
      this.$store.dispatch("addPhotoStudio", {
        id: this.currentLoanDress.pivot.id,
        form: this.modelPayment,
      });
      this.cancelPhotoStudio();
    },
  },
};
</script>
